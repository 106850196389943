<!-- 左边导航栏组件 -->
<template>
  <div :class="isCollapse ? 'no_menu_box':'menu_box'">
    <el-menu
    ref="menu"
    mode="vertical"
      :default-active="defaultActive"
      class="el-menu-vertical-demo"
      @open="handleOpen"
      :unique-opened="true"
      router
      :collapse="isCollapse"
    >
      <el-menu-item index="/dashboard" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/dashboard')">
        <i class="el-icon-menu" style="width: 12px;font-size: 16px;margin-left: -2px;"></i>
        <span>首页</span>
      </el-menu-item>
      <!-- <el-submenu index="2">
        <template slot="title">
          <i class="iconfont icon-shanghuguanli2" style="font-size: 19px;margin-left: -4px;"></i>
          <span>商戶管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/corp/list">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-liebiao"></i>
                  <span>商戶列表</span>
              </div>
          </el-menu-item>
         <el-menu-item index="/corp/payment">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-fl-renminbi"></i>
                  <span>扣费明细</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/corp/rechargerecord">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-chongzhijilu-hover"></i>
                  <span>充值记录</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title">
          <i class="iconfont icon-zhangdanguanli" style="font-size: 14px;margin-left: 0px;"></i>
          <span>账单管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/bill/detail">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-zhangdanmingxi01"></i>
                  <span>账单明细</span>
              </div>
          </el-menu-item>
         <el-menu-item index="/bill/count">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-tongjitu"></i>
                  <span>账单统计</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
      <el-submenu index="4" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/health')">
        <template slot="title">
          <i class="iconfont icon-tijian"></i>
          <span>体检业务</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/health/examine" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/health/examine')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-shenhe"></i>
                  <span>体检审核</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/photo')">
        <template slot="title">
          <i class="iconfont icon-zhaoxiangji"></i>
          <span>照相业务</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/photo/list" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/photo/list')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-xiangpian"></i>
                  <span>相片列表</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="6" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/answer')">
        <template slot="title">
          <i class="iconfont icon-dati"></i>
          <span>答题管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/answer/color_force" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/answer/color_force')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-yanjing"></i>
                  <span>辨色力</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/answer/left_eye_vision" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/answer/left_eye_vision')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-shilibiao"></i>
                  <span>左眼视力</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/answer/right_eye_vision" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/answer/right_eye_vision')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-shilibiao"></i>
                  <span>右眼视力</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/answer/hearing" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/answer/hearing')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-tinglizhuanxiangke"></i>
                  <span>听力</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="9" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/network')">
        <template slot="title">
          <i class="iconfont icon-wangdianguanli"></i>
          <span>网点管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/network/service" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/network/service')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-zhongduofuwuwangdian-15"></i>
                  <span>服务网点</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="10" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/region')">
        <template slot="title">
          <i class="iconfont icon-diqu"></i>
          <span>地区管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/region/region" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/region/region')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-renzhengdiqu"></i>
                  <span>服务地区</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="12" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/withdrawal')">
        <template slot="title">
          <i class="iconfont icon-tixianguanli"></i>
          <span>提现管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/withdrawal/list" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/withdrawal/list')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-tixianjilu"></i>
                  <span>提现记录</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="7" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/system')">
        <template slot="title">
          <i class="iconfont icon-zhanghaoguanli-tongyong-5"></i>
          <span>账号管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/system/user" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/system/user')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-yonghuguanli"></i>
                  <span>用户管理</span>
              </div>
          </el-menu-item>
         <el-menu-item  index="/system/role" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/system/role')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-he_42jiaoseguanli"></i>
                  <span>角色管理</span>
              </div>
          </el-menu-item>
          <el-menu-item  index="/system/member" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/system/member')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-huiyuanguanli"></i>
                  <span>会员管理</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="11" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration')">
        <template slot="title">
          <i class="iconfont icon-peizhiguanli1"></i>
          <span>配置管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  index="/configuration/retail" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration/retail')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-fenxiao"></i>
                  <span>会员分销</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/configuration/network_distribution" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration/network_distribution')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-demand"></i>
                  <span>网点分销</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/configuration/qutlet_requirements" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration/qutlet_requirements')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-demand"></i>
                  <span>网点入驻协议</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/configuration/online_service" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration/online_service')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-kefuguanli"></i>
                  <span>客服管理</span>
              </div>
          </el-menu-item>
          <el-menu-item index="/configuration/app_version" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/configuration/app_version')">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-banben"></i>
                  <span>APP版本管理</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="13" v-if="routeArr.root == 1 && userInfo.role_id == 0 || routerList.includes('/syslog')">
        <template slot="title">
          <i class="iconfont icon-anquanrizhi"></i>
          <span>安全日志</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/syslog/index">
              <div style="width: 100%;" class="item_box">
                  <i class="iconfont icon-rizhijilu"></i>
                  <span>日志记录</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      <!-- <el-submenu index="8">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>模板打印</span>
        </template>
        <el-menu-item-group>
          <el-menu-item index="/print/tjprint">
              <div style="width: 100%;" class="item_box">
                  <i class="el-icon-platform-eleme"></i>
                  <span>体检表打印</span>
              </div>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
    </el-menu>
  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: window.localStorage.getItem('userInfo') && JSON.parse(window.localStorage.getItem('userInfo')) || '',
      defaultActive: '/dashboard',
      isCollapse: false, // 默认不展开
      routerList: [], // 路由数组
      routeArr: {}, // 权限
    };
  },
  watch: {
    $route: {
      handler(val) {
        if (val.fullPath == '/password' || val.fullPath == '/personal') {
          this.$refs.menu.activeIndex = null
        }else {
          this.defaultActive = val.fullPath
        }
      },
      deep: true
    }
  },
  created() {
    let href = window.location.href.split('#')[1]
    this.defaultActive = href
  },
  mounted() {
    this.$bus.$on('busEmitCollapse', res => {
      this.isCollapse = res
    })
    this.getAuthRoutes() //获取缓存路由
    
  },
  methods: {

    // 获取缓存路由
    getAuthRoutes() {
      this.routeArr = JSON.parse(window.localStorage.getItem('AuthList')) || []
      let arr = []
      if (this.routeArr.root == 1) {

      } else if (this.routeArr.root != 1 && this.routeArr.role && this.routeArr.role.length > 0) {
        this.routeArr.role.forEach(item => {
          arr.push(item.path)
          if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
              arr.push(child.path)
            })
          }
        })
        this.routerList = arr
      }

    },

    handleOpen(key) {
      // this.defaultActive = key
    }
  }
};
</script>

<style lang="less">
.el-menu--vertical {
.el-menu-item-group__title {
      padding: 0px !important;
    }
}
</style>
<style lang="less" scoped>

.item_box {
  width: 100%;
      display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
}
.no_menu_box {
  &::v-deep {
    .el-menu {
      background-color: #f0f0f0;
      border-right: solid 1px #f0f0f0;
    }

    .is-active {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
         border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }


    .is-opened {
        background-color: #f0f0f0;
    }

    .el-menu-item:hover {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }

    .el-submenu__title:hover {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }
    .el-menu-item, .el-submenu__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px !important;
        padding: 0 16px !important;
        // min-width: 140px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        height: 45px;
        // width: 165px;

        span {
            margin-left: 6px;
        }
    }

    .el-submenu__icon-arrow {
        right: 30px;
    }

    .el-menu-item-group__title {
        padding: 0 !important;
    }
  }
}
.menu_box {

  &::v-deep {
    .el-menu {
      background-color: #f0f0f0;
      border-right: solid 1px #f0f0f0;
    }

    .is-active {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
         border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }

    .is-opened {
        background-color: #f0f0f0;
    }

    .el-menu-item:hover {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }

    .el-submenu__title:hover {
        background-color: #fff;
        border-top-right-radius: 30px;
         border-bottom-right-radius: 30px;
    }

    .el-menu-item, .el-submenu__title {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 16px !important;
        padding: 0 16px !important;
        min-width: 140px;
        border-top-right-radius: 30px;
        border-bottom-right-radius: 30px;
        height: 45px;
        width: 165px;

        span {
            margin-left: 6px;
        }
    }

    .el-submenu__icon-arrow {
        right: 30px;
    }

    .el-menu-item-group__title {
        padding: 0 !important;
    }
  }
}
</style>
