<!-- 内容顶部导航组件 -->
<template>
    <div class="dh_box left_center">
        <i class="cursor iconfont" :class="isCollapse == '2' ? 'icon-shouqicaidan': 'icon-zhankaicaidan'" @click="actionCollapse"></i>
        <div class="title">{{title}}</div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            isCollapse: window.localStorage.getItem('isCollapse') || '2',
        }
    },
    props: {
        title: {
            type: String,
            default: ''
        }
    },
    methods: {
        actionCollapse() {
            let bol = false
            if (this.isCollapse == '1') {
                this.isCollapse = '2'
                bol = false
            } else {
                this.isCollapse = '1'
                 bol = true
            }
            window.localStorage.setItem('isCollapse', this.isCollapse)
            this.$bus.$emit('busEmitCollapse', bol)
        }
    }
}
</script>

<style scoped lang="less">
.dh_box {
    height: 40px;

    i {
        font-size: 20px;
        font-weight: 600;
        color: #838A9D;
    }

    .title {
        font-size: 14px;
        color: #838A9D;
        margin-left: 10px;
    }
}
</style>
