import axios from 'axios'
import { Message } from 'element-ui'
import router from '@/router/index'

// 记录和显示错误
function errorLog(info) {
    // 显示提示
    Message({
        message: info,
        type: 'error',
        duration: 5 * 1000
    })
}

// 配置默认的路由地址
// axios.defaults.baseURL = 'https://tijian.1907cloud.cn/'
axios.defaults.baseURL = 'https://zytijian.1907cloud.cn/'

// 全局添加拦截器的作用是在每个api前面加三headers的token验证
axios.interceptors.request.use(config => {
    // 判断token是否存在和是否需要token验证的路由
    if (localStorage.getItem('token')) {
        config.headers['token'] = localStorage.getItem('token') || ''
        // config.headers.Authorization = localStorage.getItem('token')
    }
        // 防止缓存，GET请求默认带_t参数
        if (config.method === 'get') {
            config.params = {
                ...config.params,
                '_t': new Date().getTime()
            }
        }
    return config
}, error => {
    return Promise.reject(error)
})

// 全局添加拦截器的作用是在每个api前面加三headers的token验证
axios.interceptors.response.use(response => {
    if (response.data.code === undefined) {
        return response.data
    } else if (String(response.data.code) == String(-1)) {
        Message({
            message: '登录已失效',
            duration: 2 * 1000
        })
        router.push({ path: '/login' })
        return Promise.reject(new Error('登录已失效'))
    } else if (String(response.data.code) !== String(1) && String(response.data.code) !== String(200)) {
        errorLog(response.data.msg)
        return Promise.reject(response.data)
    } else {
        return response.data
    }
}, error => {
    errorLog(error.message)
    return Promise.reject(error)
})

export default axios