<!-- 头部固定组件 -->
<template>
    <div class="header_box space_center bg_primary">
        <div class="left_box left_center">
            <img src="~@/assets/logo.png" alt="" class="logo">
            <div class="logo_name">驾驶人自助拍照体检系统</div>
        </div>
        <div class="right_box right_center">

            <div class="left_center icon_box">
                <el-tooltip class="item" effect="dark" content="刷新" placement="bottom">
                    <div class="icon_sm row_center cursor" @click="actionRefresh">
                        <i class="el-icon-refresh-left"></i>
                    </div>
                </el-tooltip>
            </div>
            
            <el-dropdown placement="bottom">
                <div class="left_center user_box cursor">
                    <img :src="userInfo.avatar" alt="" class="avatar"/>
                    <div class="user_name">{{userInfo.name}}</div>
                </div>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="$router.push('/personal')" v-if="routeArr.root == 1 || routerList.includes('/personal')">个人中心</el-dropdown-item>
                    <!-- <el-dropdown-item @click.native="$router.push('/password')" v-if="routeArr.root == 1 || routerList.includes('/password')">修改密码</el-dropdown-item> -->
                    <el-dropdown-item @click.native="actionLogout">退出登录</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            routerList: [], // 缓存路由
            routeArr: {}
        }
    },
    props: {
        userInfo: {
            type: [Object],
            default: () => {}
        }
    },
    created() {
        this.getAuthRoutes() // 获取缓存路由
    },
    // mounted() {
    //     this.$bus.$on('auth', res => {
    //         this.routeArr = JSON.parse(res)
    //     })
    // },
    methods: {
     // 获取缓存路由
    getAuthRoutes() {
      this.routeArr = JSON.parse(window.localStorage.getItem('AuthList')) || []
      let arr = []
      if (this.routeArr.root == 1) {

      } else if (this.routeArr.root != 1 && this.routeArr.role && this.routeArr.role.length > 0) {
        this.routeArr.role.forEach(item => {
          arr.push(item.path)
          if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
              arr.push(child.path)
            })
          }
        })
        this.routerList = arr
      }
    },
        // 刷新页面
        actionRefresh() {
            this.$router.go(0)
            return
            let href = window.location.href
            this.$bus.$emit('pageRefresh', href)
            return
            if (href.split('/#/')[1] == 'health/examine') { // 体检审核
                this.$bus.$emit('pageRefresh', 'examine')
            } else if (href.split('/#/')[1] == 'photo/list') { // 相片列表
                this.$bus.$emit('pageRefresh', 'photo')
            } else if (href.split('/#/')[1] == 'network/service') { // 服务网点
                this.$bus.$emit('pageRefresh', 'service')
            } else if (href.split('/#/')[1] == 'withdrawal/list') { // 提现记录
                this.$bus.$emit('pageRefresh', 'withdrawal')
            } else if (href.split('/#/')[1] == 'dashboard') { // 首页
                this.$bus.$emit('pageRefresh', 'dashboard')
            } else if (href.split('/#/')[1] == 'answer/color_force') { // 视力
                this.$bus.$emit('pageRefresh', 'color_force')
            } else if (href.split('/#/')[1] == 'answer/left_eye_vision') { // 左眼
                this.$bus.$emit('pageRefresh', 'left_eye_vision')
            } else if (href.split('/#/')[1] == 'answer/right_eye_vision') { // 右眼
                this.$bus.$emit('pageRefresh', 'right_eye_vision')
            } else if (href.split('/#/')[1] == 'answer/hearing') { // 听力
                this.$bus.$emit('pageRefresh', 'hearing')
            }
        },
        // 退出登录
        actionLogout() {
            window.localStorage.setItem('token', '')
            window.localStorage.setItem('AuthList', '')
            window.localStorage.setItem('userInfo', '')
            // this.$router.push('/login')
            window.location.href = '/'
        }
    }
}
</script>

<style scoped lang="less">
.header_box {
    height: 48px;
    width: 100%;
    padding-left: 10px;
    box-sizing: border-box;

    .left_box {

        .logo {
            width: 35px;
            // height: 35px;
        }

        .logo_name {
            font-size: 22px;
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            padding-left: 15px;
            font-weight: 700;
            color: #fff;
        }
    }

    .right_box {

        .user_box:hover {
            background-color: #62a8f1;
            border-radius: 2px;
        }
        .user_box {
            padding: 2px 20px;
            
            .avatar {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin-right: 10px;
            }

            .user_name {
                font-size: 18px;
                font-weight: 700;
                color: #fff;
            }
        }

        .icon_box {
            height: 48px;

            .icon_sm:hover {
                background-color: #62a8f1;
            }
            .icon_sm {
                width: 50px;
                height: 100%;

                i {
                    font-size: 20px;
                    color: #fff;
                }
            }
        }
    }
}
</style>
