<template>
    <div class="delete_dialog">
        <el-dialog
        title="提示"
        :visible.sync="visibleShow"
        width="30%">
        <span>{{title}}</span>
        <span slot="footer" class="dialog-footer">
            <el-button @click="visibleShow = false" size="small">取 消</el-button>
            <el-button type="primary" @click="$emit('emitDelete')" size="small">确 定</el-button>
        </span>
    </el-dialog>
    </div>
</template>

<script>
export default {
    data() {
        return {
            visibleShow: false
        }
    },
     props: {
        visible: {
            type: Boolean,
            default: false
        },
        title: {
          type: String,
          default: ''
        }
    },
    watch: {
        visible: {
            handler(val) {
                this.visibleShow = val
            },
            immediate: true
        },
        visibleShow(val) {
            if (!val) {
                this.$emit('closeVisible')
            }
        },
    },
}
</script>

<style lang="less">
.delete_dialog {
    .el-dialog__body {
    padding: 0;
        font-size: 16px;
    padding: 10px;
}
}
</style>