import Vue from 'vue'
import VueRouter from 'vue-router'

// import Login from './components/Login.vue'
const noData = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/404.vue')
const Login = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/login.vue')
const Home = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/home.vue')
const Dashboard = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/dashboard.vue')
const Password = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/password.vue')
const Personal = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/personal.vue')
const Corplist = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/corp/list.vue')
const Payment = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/corp/payment.vue')
const Rechargerecord = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/corp/rechargerecord.vue')
const Billdetail = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/bill/detail.vue')
const Billcount = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/bill/count.vue')
const Healthexamine = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/health/examine.vue')
const Photolist = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/photo/list.vue')
const Systemuser = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/system/user.vue')
const Printtiprint = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/print/tjprint.vue')
const SystemRole = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/system/role.vue')
const SystemMember = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/system/member.vue')
const AnswerColorforce = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/answer/color_force.vue')
const leftEyeVision = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/answer/left_eye_vision.vue')
const rightEyeVision = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/answer/right_eye_vision.vue')
const Hearing = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/answer/hearing.vue')
const networkService = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/network/service.vue')
const Region = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/region/region.vue')
const Retail = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/system/retail.vue')
const NetworkDistribution = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/configuration/network_distribution.vue')
const QutletRequirements = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/configuration/qutlet_requirements.vue')
const OnlineService = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/configuration/online_service.vue')
const AppVersion = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/configuration/app_version.vue')
const WithdrawalList = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/withdrawal/list.vue')
const syslogIndex = () => import(/* webpackChunkName: "Login_Home_Welcome" */ '@/pages/syslog/index.vue')

const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter)

const routes = [
  { path: '*', redirect: '/404' },
  { path: '/', redirect: '/login' },
  { path: '/404', component: noData },
  { path: '/login', component: Login },
  // 重定向Welcome页面
  // { path: '/home', component: Home, redirect: '/Welcome', children: [{ path: '/welcome', component: Welcome }, { path: '/users', component: Users }, { path: '/rights', component: Rights }, { path: '/roles', component: Roles }, { path: '/categories', component: Cate }, { path: '/params', component: Params }, { path: '/goods', component: GoodsList }, { path: '/goods/add', component: Add }, { path: '/orders', component: Order }, { path: '/reports', component: Report }] }
]

// 权限路由
const authRoute = [
  { path: '/home', component: Home, redirect: '/dashboard',
  children:[
    { path: '/dashboard', component: Dashboard, meta: {title: '首页-驾驶人自助拍照体检系统'} },
    { path: '/corp/list', component: Corplist, meta: {title: '商户列表-驾驶人自助拍照体检系统'}},
    { path: '/corp/payment', component: Payment, meta: {title: '扣费明细-驾驶人自助拍照体检系统'} },
    { path: '/corp/rechargerecord', component: Rechargerecord, meta: {title: '充值记录-驾驶人自助拍照体检系统'} },
    { path: '/bill/detail', component: Billdetail, meta: {title: '账单明细-驾驶人自助拍照体检系统'} },
    { path: '/bill/count', component: Billcount, meta: {title: '账单统计-驾驶人自助拍照体检系统'} },
    { path: '/health/examine', component: Healthexamine, meta: {title: '体检审核-驾驶人自助拍照体检系统'} },
    { path: '/photo/list', component: Photolist, meta: {title: '相片列表-驾驶人自助拍照体检系统'} },
    { path: '/system/user', component: Systemuser, meta: {title: '用户管理-驾驶人自助拍照体检系统'} },
    { path: '/print/tjprint', component: Printtiprint, meta: {title: '体检表打印-驾驶人自助拍照体检系统'} },
    { path: '/system/role', component: SystemRole, meta: {title: '角色管理-驾驶人自助拍照体检系统'} },
    { path: '/system/member', component: SystemMember, meta: {title: '会员管理-驾驶人自助拍照体检系统'} },
    { path: '/answer/color_force', component: AnswerColorforce, meta: {title: '辨色力-驾驶人自助拍照体检系统'} },
    { path: '/answer/left_eye_vision', component: leftEyeVision, meta: {title: '左眼视力-驾驶人自助拍照体检系统'} },
    { path: '/answer/right_eye_vision', component: rightEyeVision, meta: {title: '右眼视力-驾驶人自助拍照体检系统'} },
    { path: '/answer/hearing', component: Hearing, meta: {title: '听力-驾驶人自助拍照体检系统'} },
    { path: '/network/service', component: networkService, meta: {title: '服务网点-驾驶人自助拍照体检系统'} },
    { path: '/region/region', component: Region, meta: {title: '服务地区-驾驶人自助拍照体检系统'} },
    { path: '/configuration/retail', component: Retail, meta: {title: '会员分销-驾驶人自助拍照体检系统'} },
    { path: '/configuration/network_distribution', component: NetworkDistribution, meta: {title: '网点分销-驾驶人自助拍照体检系统'} },
    { path: '/configuration/qutlet_requirements', component: QutletRequirements, meta: {title: '网点入驻协议-驾驶人自助拍照体检系统'} },
    { path: '/configuration/online_service', component: OnlineService, meta: {title: '客服管理-驾驶人自助拍照体检系统'} },
    { path: '/configuration/app_version', component: AppVersion, meta: {title: 'APP版本管理-驾驶人自助拍照体检系统'} },
    { path: '/withdrawal/list', component: WithdrawalList, meta: {title: '提现管理-驾驶人自助拍照体检系统'} },
    { path: '/syslog/index', component: syslogIndex, meta: {title: '日志记录-驾驶人自助拍照体检系统'} },
    // { path: '/password', component: Password, meta: {title: '修改密码-驾驶人自助拍照体检系统'} },
    { path: '/personal', component: Personal, meta: {title: '个人中心-驾驶人自助拍照体检系统'} },
  ]
}
]

let AuthList = window.localStorage.getItem('AuthList') && JSON.parse(window.localStorage.getItem('AuthList')) || ''
let arr = []
let routerArr = []
if (!AuthList) {
  routerArr = authRoute
} else if (AuthList.root == 1) {
routerArr = authRoute
} else {
  authRoute[0].children.forEach(path => {
    if (AuthList.role && AuthList.role.length > 0) {
      AuthList.role.forEach(item => {
        if (path.path == item.path) {
          arr.push(path)
        } else {
          if (item.children && item.children.length > 0) {
            item.children.forEach(child => {
              if (child.path == path.path) {
                arr.push(path)
              }
            })
          }
        }
      })
    }
  })
  routerArr = [
    { path: '/home', component: Home, redirect: arr[0],
      children: [
        // { path: '/password', component: Password, meta: {title: '修改密码-驾驶人自助拍照体检系统'} },
        { path: '/personal', component: Personal, meta: {title: '个人中心-驾驶人自助拍照体检系统'} },
        ...arr
      ]
    }
  ]
}
console.log('routerArr', routerArr)



const router = new VueRouter({
  routes
})

router.addRoutes(routerArr)

// <!--挂载路由导航守卫,控制登录页面获取token值跳转页面-->

router.beforeEach((to, from, next) => {
  // to 将要访问的路径
  // feom 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next() 表示放行   next('/login') 表示强制跳转登录页面

  if (to.meta.title) {
    document.title = to.meta.title
  } else {
    document.title = '驾驶人自助拍照体检系统'
  }

  if (to.path === '/login') return next();
  // 获取token
  const tokenStr = window.localStorage.getItem('token');
  if (!tokenStr) return next('/login')
  next();
})

export default router
