<template>
  <div id="app" class="app">
    <!-- 路由占位符-->
   <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style lang="less">
@import './assets/common.less'; // 常用公共样式
@import './assets/flex.less'; // 常用公共样式
@import './assets/iconfont.css';

.app {
  background-color: #f0f0f0;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
  width: 100%;
  height: 100%;
}
body, html {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  width: 8px;
    height: 8px;
    padding-right: 2px;
    background: rgba(242,242,242,.5);
}
::-webkit-scrollbar-thumb {
  background: rgba(180,187,197,0.2);
    border-radius: 10px;
    border-width: 0px;
    border-style: initial;
    border-color: initial;
    border-image: initial;
}
</style>
